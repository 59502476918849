"use strict";

var _interopRequireDefault = require("@babel/runtime/helpers/interopRequireDefault");
var _typeof = require("@babel/runtime/helpers/typeof");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.ThankYouSubMessage = exports.Row = exports.MoreInfoContainer = exports.Heading = exports.Head = exports.Drawer = exports.Disclaimer = exports.Column = exports.CloseButtonContainer = exports.CloseButton = exports.CheckboxContainer = exports.Body = void 0;
var _taggedTemplateLiteral2 = _interopRequireDefault(require("@babel/runtime/helpers/taggedTemplateLiteral"));
var _styledComponents = _interopRequireWildcard(require("styled-components"));
var _RollsRoyce = _interopRequireWildcard(require("../../../customThemes/RollsRoyce"));
var _templateObject, _templateObject2, _templateObject3, _templateObject4, _templateObject5, _templateObject6;
function _getRequireWildcardCache(e) { if ("function" != typeof WeakMap) return null; var r = new WeakMap(), t = new WeakMap(); return (_getRequireWildcardCache = function _getRequireWildcardCache(e) { return e ? t : r; })(e); }
function _interopRequireWildcard(e, r) { if (!r && e && e.__esModule) return e; if (null === e || "object" != _typeof(e) && "function" != typeof e) return { "default": e }; var t = _getRequireWildcardCache(r); if (t && t.has(e)) return t.get(e); var n = { __proto__: null }, a = Object.defineProperty && Object.getOwnPropertyDescriptor; for (var u in e) if ("default" !== u && {}.hasOwnProperty.call(e, u)) { var i = a ? Object.getOwnPropertyDescriptor(e, u) : null; i && (i.get || i.set) ? Object.defineProperty(n, u, i) : n[u] = e[u]; } return n["default"] = e, t && t.set(e, n), n; }
var Heading = exports.Heading = _styledComponents["default"].h1.withConfig({
  displayName: "DrawerStyles__Heading",
  componentId: "sc-86dznf-0"
})(["font-size:34px;font-weight:300;line-height:44px;letter-spacing:2.5px;color:#fff;margin:0;width:100%;text-align:center;margin-top:100px;margin-bottom:40px;text-transform:uppercase;", ""], _RollsRoyce["default"].max.large(_templateObject || (_templateObject = (0, _taggedTemplateLiteral2["default"])(["\n    font-size: 20px;\n    line-height: 26px;\n    letter-spacing: 2px;\n    margin-top: 50px;\n    margin-bottom: 24px;\n  "]))));
var Drawer = exports.Drawer = _styledComponents["default"].div.withConfig({
  displayName: "DrawerStyles__Drawer",
  componentId: "sc-86dznf-1"
})(["height:100%;background:#281432;position:fixed;top:0;right:0;width:100%;z-index:200;transform:translateY(100%);visibility:hidden;transition:transform 0.4s ease-in-out,visibility 0.4s ease-in-out;display:flex;flex-direction:column;box-sizing:border-box;align-items:center;padding:0;overflow-y:scroll;", " ", " * > p{width:100%;text-align:left;color:#fff;font-size:16px;padding:0;margin:0;margin-bottom:20px;text-wrap:pretty;}* > li{width:100%;text-align:left;color:#fff;font-size:14px;margin-bottom:10px;line-height:20px;}* > ul{box-sizing:border-box;width:100%;list-style-type:disc;padding-left:20px;margin:0;margin-bottom:30px;", "}"], _RollsRoyce["default"].max.medium(_templateObject2 || (_templateObject2 = (0, _taggedTemplateLiteral2["default"])(["\n    width: 100%;\n  "]))), function (props) {
  return props.open && (0, _styledComponents.css)(["transform:translateY(0);visibility:visible;"]);
}, _RollsRoyce["default"].min.medium(_templateObject3 || (_templateObject3 = (0, _taggedTemplateLiteral2["default"])(["\n       margin-bottom: 20px;\n    "]))));
var Head = exports.Head = _styledComponents["default"].div.withConfig({
  displayName: "DrawerStyles__Head",
  componentId: "sc-86dznf-2"
})(["display:flex;justify-content:center;width:100%;height:82px;min-height:82px;background:#1b0d2e;box-sizing:border-box;align-items:center;"]);
var Body = exports.Body = _styledComponents["default"].div.withConfig({
  displayName: "DrawerStyles__Body",
  componentId: "sc-86dznf-3"
})(["", " display:flex;flex-direction:row;width:100%;gap:50px;padding-top:100px;max-width:1562px;box-sizing:border-box;align-items:flex-start;justify-content:center;", " #reader-input{background-color:#1b0d2e !important;}"], _RollsRoyce.mixins.pageGuttering(), _RollsRoyce["default"].max.large(_templateObject4 || (_templateObject4 = (0, _taggedTemplateLiteral2["default"])(["\n    padding-top: 0px;\n  "]))));
var Column = exports.Column = _styledComponents["default"].div.withConfig({
  displayName: "DrawerStyles__Column",
  componentId: "sc-86dznf-4"
})(["display:flex;flex-direction:column;align-items:center;margin-bottom:100px;", ""], _RollsRoyce["default"].max.large(_templateObject5 || (_templateObject5 = (0, _taggedTemplateLiteral2["default"])(["\n    max-width: 516px;\n  "]))));
var CloseButton = exports.CloseButton = _styledComponents["default"].img.withConfig({
  displayName: "DrawerStyles__CloseButton",
  componentId: "sc-86dznf-5"
})(["width:16px;height:16px;cursor:pointer;"]);
var CheckboxContainer = exports.CheckboxContainer = _styledComponents["default"].div.withConfig({
  displayName: "DrawerStyles__CheckboxContainer",
  componentId: "sc-86dznf-6"
})(["margin-bottom:20px;width:100%;color:#9b9b9b;letter-spacing:0.5px;font-size:14px;line-height:24px;transition:color 0.3s ease-in-out;", " ", " ", " @media (hover:hover){&:hover{color:#fff;}}"], function (_ref) {
  var selected = _ref.selected;
  return selected && (0, _styledComponents.css)(["color:#fff;"]);
}, function (_ref2) {
  var indentCheckboxes = _ref2.indentCheckboxes;
  return indentCheckboxes && (0, _styledComponents.css)(["margin-left:0;"]);
}, _RollsRoyce["default"].min.medium(_templateObject6 || (_templateObject6 = (0, _taggedTemplateLiteral2["default"])(["\n        ", "\n    "])), function (_ref3) {
  var indentCheckboxes = _ref3.indentCheckboxes;
  return indentCheckboxes && (0, _styledComponents.css)(["margin-left:28px;"]);
}));
var MoreInfoContainer = exports.MoreInfoContainer = _styledComponents["default"].div.withConfig({
  displayName: "DrawerStyles__MoreInfoContainer",
  componentId: "sc-86dznf-7"
})(["width:100%;text-decoration:underline;text-align:left;color:#fff;font-size:16px;letter-spacing:0.5px;box-sizing:border-box;"]);
var Row = exports.Row = _styledComponents["default"].div.withConfig({
  displayName: "DrawerStyles__Row",
  componentId: "sc-86dznf-8"
})(["display:flex;flex-direction:row;justify-content:flex-start;box-sizing:border-box;width:100%;"]);
var ThankYouSubMessage = exports.ThankYouSubMessage = _styledComponents["default"].div.withConfig({
  displayName: "DrawerStyles__ThankYouSubMessage",
  componentId: "sc-86dznf-9"
})(["line-height:24px;font-size:16px;color:#fff;text-align:center;margin-bottom:30px;"]);
var Disclaimer = exports.Disclaimer = (0, _styledComponents["default"])(CheckboxContainer).withConfig({
  displayName: "DrawerStyles__Disclaimer",
  componentId: "sc-86dznf-10"
})(["& > p{color:#9b9b9b;text-decoration:none;}"]);
var CloseButtonContainer = exports.CloseButtonContainer = _styledComponents["default"].div.withConfig({
  displayName: "DrawerStyles__CloseButtonContainer",
  componentId: "sc-86dznf-11"
})(["cursor:pointer;display:flex;justify-content:center;align-items:center;box-sizing:border-box;path{stroke-dashoffset:0;}path:hover{stroke-dashoffset:143;animation:dash 0.4s linear forwards;}@keyframes dash{to{stroke-dashoffset:0;}}"]);