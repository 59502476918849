"use strict";

var _interopRequireDefault = require("@babel/runtime/helpers/interopRequireDefault");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports["default"] = void 0;
var _defineProperty2 = _interopRequireDefault(require("@babel/runtime/helpers/defineProperty"));
var _react = require("react");
var _reactRedux = require("react-redux");
var _router = require("../actions/router");
var _http = require("../helpers/http");
var _useChangeReleaseFlag = require("../utilities/useChangeReleaseFlag");
var _ComponentContext = _interopRequireDefault(require("./ComponentContext"));
var _configSelectors = require("./configSelectors");
function ownKeys(e, r) { var t = Object.keys(e); if (Object.getOwnPropertySymbols) { var o = Object.getOwnPropertySymbols(e); r && (o = o.filter(function (r) { return Object.getOwnPropertyDescriptor(e, r).enumerable; })), t.push.apply(t, o); } return t; }
function _objectSpread(e) { for (var r = 1; r < arguments.length; r++) { var t = null != arguments[r] ? arguments[r] : {}; r % 2 ? ownKeys(Object(t), !0).forEach(function (r) { (0, _defineProperty2["default"])(e, r, t[r]); }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(e, Object.getOwnPropertyDescriptors(t)) : ownKeys(Object(t)).forEach(function (r) { Object.defineProperty(e, r, Object.getOwnPropertyDescriptor(t, r)); }); } return e; }
var httpClientNext = function httpClientNext(dispatch, history) {
  return function (response) {
    if (response && response.status === 401 && !/\/signin$/.test(history.location.pathname)) {
      dispatch(_router.actions.navigate('/signin'));
    }
  };
};
var useSite = function useSite() {
  var dispatch = (0, _reactRedux.useDispatch)();
  var hashRouting = (0, _reactRedux.useSelector)(function (state) {
    return state.config.settings.hashRouting;
  });
  var reduxState = (0, _reactRedux.useSelector)(function (state) {
    return state;
  });
  var _useSelector = (0, _reactRedux.useSelector)(function (state) {
      return state.config.config.global;
    }),
    analytics = _useSelector.analytics,
    featureFlags = _useSelector.featureFlags,
    maps = _useSelector.maps;
  var _useSelector2 = (0, _reactRedux.useSelector)(function (state) {
      return state.router;
    }),
    platformMode = _useSelector2.platformMode;
  var ignoreBandList = platformMode ? ['CookiePolicy', 'Header', 'Footer', 'Push'] : null;
  var history = (0, _reactRedux.useSelector)(function (state) {
    return state.router.history;
  });
  var site = (0, _reactRedux.useSelector)(function (s) {
    return s.config.site;
  });
  var siteBands = (0, _reactRedux.useSelector)(function (s) {
    return site.map(function (gm) {
      return s.config.bands[gm];
    });
  });
  var siteBandsForPath = siteBands.map(function (band) {
    return (0, _configSelectors.bandWithConfigSelector)(band, hashRouting, reduxState);
  });
  var _useContext = (0, _react.useContext)(_ComponentContext["default"]),
    Body = _useContext.Body;
  (0, _react.useEffect)(function () {
    (0, _http.httpClientIntercept)(httpClientNext(dispatch, history));
  }, [dispatch]);
  var env = (0, _useChangeReleaseFlag.getEnviromentFromUrl)();
  return {
    maps: _objectSpread(_objectSpread(_objectSpread({}, maps), env === 'prod' && maps.prodApiKey ? {
      apiKey: maps.prodApiKey
    } : {}), env === 'prod' && maps.mapsEmbedKeyProd ? {
      mapsEmbedKey: maps.mapsEmbedKeyProd
    } : {}),
    analytics: analytics,
    featureFlags: featureFlags,
    siteBands: siteBandsForPath,
    Body: Body,
    ignoreBandList: ignoreBandList
  };
};
var _default = exports["default"] = useSite;