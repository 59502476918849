"use strict";

var _interopRequireDefault = require("@babel/runtime/helpers/interopRequireDefault");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.vinValidityCheck = exports.valueMatchesRequired = exports.valueMatches = exports.valueIsTrue = exports.valueIsNull = exports.validSelectedAddress = exports.validPhoneNumber = exports.validPhoneByCode = exports.validPhone = exports.validEmail = exports.required = exports.passwordMatchesRequired = exports.oneOptionRequired = exports.meetsPasswordPolicy = exports.bentleyValidPhoneRegex = void 0;
var _googleLibphonenumber = _interopRequireDefault(require("google-libphonenumber"));
var _validate = _interopRequireDefault(require("validate.js"));
_validate["default"].validators.isValidPhone = function (value, options) {
  var regex = /^\+?[0-9]+$/;
  if (value !== '' && !regex.exec(value)) return options.message;
  return undefined;
};
_validate["default"].validators.checkIfNotUndefined = function (value, options) {
  if (value && (value.id === 'undefined' || !value.id)) return options.message;
  return undefined;
};
var vinValidityCheck = exports.vinValidityCheck = function vinValidityCheck(value, translations) {
  return _validate["default"].single(value, {
    presence: {
      allowEmpty: false,
      message: translations.formValidationRequired
    },
    length: {
      minimum: 17,
      tooShort: translations.invalidVin,
      maximum: 25,
      tooLong: translations.invalidVin
    }
  });
};
var required = exports.required = function required(value, translations) {
  return _validate["default"].single(value, {
    presence: {
      allowEmpty: false,
      message: translations.formValidationRequired
    }
  });
};
var oneOptionRequired = exports.oneOptionRequired = function oneOptionRequired(value, translations) {
  return _validate["default"].single(value, {
    presence: {
      allowEmpty: false,
      message: translations.formValidationOneOptionRequired
    }
  });
};
var validSelectedAddress = exports.validSelectedAddress = function validSelectedAddress(value, translations) {
  return _validate["default"].single(value, {
    presence: {
      allowEmpty: false,
      message: translations.formValidationRequired
    },
    checkIfNotUndefined: {
      message: translations.notSupportedAddress
    }
  });
};
var validEmail = exports.validEmail = function validEmail(value, translations) {
  return _validate["default"].single(value, {
    presence: {
      allowEmpty: false,
      message: translations.formValidationRequired
    },
    email: {
      message: translations.formValidationEmail
    }
  });
};
var valueIsTrue = exports.valueIsTrue = function valueIsTrue(value, translations) {
  return value !== true ? [translations.formValidationRequired] : undefined;
};
var valueIsNull = exports.valueIsNull = function valueIsNull(value, translations) {
  return value !== null ? [translations.formValidationRequired] : undefined;
};
var valueMatchesRequired = exports.valueMatchesRequired = function valueMatchesRequired(value, comparedValue, translations) {
  if (!comparedValue) return [translations.formValidationRequired];
  if (value !== comparedValue) return [translations.formValidationisValidPhoneMatch];
  return undefined;
};
var meetsPasswordPolicy = exports.meetsPasswordPolicy = function meetsPasswordPolicy(value, translations, isRequired) {
  var policy = [/[0-9]+/, /[A-Z]+/, /[a-z]+/, /.{10,}/, /[^\da-zA-Z]/];
  if (isRequired && !value) return [translations.formValidationRequired];
  if (value && !policy.every(function (rule) {
    return value.match(rule);
  })) {
    return [translations.formValidationPasswordPolicy];
  }
  return undefined;
};
var passwordMatchesRequired = exports.passwordMatchesRequired = function passwordMatchesRequired(value, comparedValue, translations) {
  if (!comparedValue) return [translations.formValidationRequired];
  if (value !== comparedValue) {
    return [translations.formValidationPasswordMatch];
  }
  return undefined;
};
var valueMatches = exports.valueMatches = function valueMatches(value, comparedValue, error) {
  if (value !== comparedValue) return [error];
  return undefined;
};
var validPhone = exports.validPhone = function validPhone(value, translations) {
  return _validate["default"].single(value, {
    presence: {
      allowEmpty: true,
      message: translations.formValidationRequired
    },
    isValidPhone: {
      message: translations.formValidationPhone
    }
  });
};
var validPhoneByCode = exports.validPhoneByCode = function validPhoneByCode(value, code, translations) {
  if (!code || !value || value.length <= 2) {
    return [translations.formValidationPhone];
  }
  var phoneUtil = _googleLibphonenumber["default"].PhoneNumberUtil.getInstance();
  var result = phoneUtil.isValidNumberForRegion(phoneUtil.parse(value, code.toUpperCase()), code.toUpperCase()) ? undefined : [translations.formValidationPhone];
  return result;
};
var validPhoneNumber = exports.validPhoneNumber = function validPhoneNumber(value, translations) {
  if (value == null) {
    return [translations.formValidationPhone];
  }
  return value.length > 8 && value.length <= 15 ? undefined : [translations.formValidationPhone];
};
var bentleyValidPhoneRegex = exports.bentleyValidPhoneRegex = function bentleyValidPhoneRegex(value, translations) {
  return value.length >= 9 && value.length <= 22 ? undefined : [translations.formValidationPhone];
};